<template>
  <BaseDraggableModal
      name="create_certificate"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      ref="create_certificate">
    <v-card :loading="loading" :disabled="loading">
      <BaseModal>
        <v-toolbar dark color="primary" dense flat class="draggable_selector" slot="header">
          <v-btn icon @click.stop="reset">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ page.title }}</v-toolbar-title>
          <v-spacer/>
          <v-btn text small @click.stop="save">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-toolbar>
        <div slot="content">
          <v-divider/>
          <v-card-text>
            <!--USUAL FIELDS-->
            <base-field
                v-for="field in usual_fields"
                :key="field.name"
                v-model="record[field.name]"
                :field="field"
                @change="usualFieldDataChanged"/>
            <!--FILE OR TEMPLATE SELECTOR-->
            <v-btn-toggle light v-model="file_or_template">
              <v-btn small value="file">{{ $store.getters.translate('upload_file') }}</v-btn>
              <v-btn small value="template">{{ $store.getters.translate('generate_from_template') }}</v-btn>
            </v-btn-toggle>
            <!--SELECT TRAINING-->
            <v-autocomplete
                class="mt-3"
                v-if="file_or_template === 'template'"
                :label="$store.getters.translate('training')"
                v-model="training_id"
                :items="trainings"
                item-value="id"
                item-text="name"
                :return-object="false"
                clearable/>
            <!--SELECT TEMPLATE-->
            <v-autocomplete
                v-if="file_or_template === 'template'"
                :label="$store.getters.translate('template')"
                v-model="template_id"
                :items="templates"
                item-value="id"
                item-text="name"
                :return-object="false"
                clearable/>
            <!--SELECT TEMPLATE LANGUAGE-->
            <v-btn-toggle v-if="file_or_template === 'template'" light v-model="certificate_language">
              <v-btn small value="nl">{{ $store.getters.translate('nl') }}</v-btn>
              <v-btn small value="en">{{ $store.getters.translate('en') }}</v-btn>
            </v-btn-toggle>
            <!--UPLOAD FILES-->
            <v-row v-if="file_or_template === 'file'">
              <v-col v-cloak @drop.prevent="addDropFile" @dragover.prevent>
                <v-file-input
                    class="mt-3"
                    :label="$store.getters.translate('files')"
                    placeholder="Drop file to upload"
                    show-size
                    v-model="new_files"/>
              </v-col>
            </v-row>
          </v-card-text>
        </div>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import helpFunctions from "../../plugins/helpFunctions";
import BaseModal from "../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../components/commonComponents/BaseDraggableModal";

export default {
  props: ["trainings"],
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  data() {
    return {
      page: helpFunctions.pages.certificates,
      loading: false,
      record: {},
      usual_fields: [],
      new_files: [],
      file_or_template: "file",
      certificate_language: "nl",
      training_id: null,
      template_id: null,
      templates: [],
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    load() {
      this.record = {};
      this.openModal('create_certificate');
      this.getTemplates();
      this.getFields();
    },
    async save() {
      if(!this.loading) {
        if (helpFunctions.modelFieldsFilled(this.$toasted, this.record, this.usual_fields)) {
          this.loading = true;
          let record_id = await helpFunctions.createModel(this.$toasted, this.page.name, this.record);
          if(record_id) {
            if(this.file_or_template === 'template') {
              await this.$http.post(this.$store.getters.appUrl + "v2/pdf/generate-certificate", {
                worker_id: this.record.worker_id,
                certificate_id: record_id,
                certificate_language: this.certificate_language,
                training_id: this.training_id,
                template_id: this.template_id
              })
              .catch((error) => {
                this.$toasted.error(error);
                this.loading = false;
              });
            }
            else if(this.new_files.length > 0 || ("name" in this.new_files)) {
              await this.uploadFiles(this.new_files, record_id);
            }
            this.$emit("refresh", record_id);
            this.reset();
          }
          this.loading = false;
        }
      }
    },
    reset() {
      this.record = {};
      this.new_files = [];
      this.file_or_template = "file";
      this.certificate_language = "nl";
      this.training_id = null;
      this.template_id = null;
      this.closeModal('create_certificate');
    },
    usualFieldDataChanged(data) {
      if(this.record[data[0]] !== data[1]) {
        this.record[data[0]] = data[1];
      }
    },
    async getTemplates() {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/templates")
          .then((response) => {
            this.templates = [];
            response.data.forEach((option) => {
              this.templates.push({
                id: option.id,
                name: option.name,
              });
            });
            this.loading = false;
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
    async getFields() {
      this.loading = true;
      const response = await helpFunctions.getFields(this.page.model, "show_on_creation");
      this.usual_fields = response.usual_fields;
      this.loading = false;
    },
    addDropFile(e) {
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        this.new_files.push(e.dataTransfer.files[i]);
      }
    },
    async uploadFiles(files, record_id) {
      this.loading = true;
      const formData = new FormData();
      formData.append("attachment", files);
      formData.append("id", record_id);
      await this.$http.post(this.$store.getters.appUrl + "v2/" + this.page.name + "/uploadfiles", formData, {headers: {"Content-Type": "multipart/form-data"}});
    },
  },
};
</script>